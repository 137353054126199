import { Typography, AppBar, Grid } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import Texas_Foster_Care256x256 from "../../assets/img/logos/Texas_Foster_Care256x256.png";
import "./appbar.css";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import FamilyRestroomIcon from "@mui/icons-material/FamilyRestroom";
// import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import ChecklistRtlIcon from "@mui/icons-material/ChecklistRtl";
import FlagIcon from "@mui/icons-material/Flag";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import Diversity3Icon from "@mui/icons-material/Diversity3";

import React, { useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import Tooltip from "@mui/material/Tooltip";
import FlagCircleIcon from "@mui/icons-material/FlagCircle";
import SettingsAccessibilityIcon from "@mui/icons-material/SettingsAccessibility";
import Home from "../home/home";
import Togglemenu from "./togglemenu";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
// import { Dropdown } from "@mui/base/Dropdown";
// import { MenuButton } from "@mui/base/MenuButton";
// import { Fab } from "@mui/material";
// import CallIcon from "@material-ui/icons/Call";
// import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
// import FacebookIcon from "@mui/icons-material/Facebook";
// import TwitterIcon from "@mui/icons-material/Twitter";
// import PhoneIcon from "@mui/icons-material/Phone";
// import InstagramIcon from "@mui/icons-material/Instagram";
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const Headermenu0 = () => {
  const [anchorElOverview, setAnchorElOverview] = React.useState(null);
  const openOverview = Boolean(anchorElOverview);

  const [anchorElGetStarted, setAnchorElGetStarted] = React.useState(null);
  const openGetStarted = Boolean(anchorElGetStarted);

  // Event handlers for the first dropdown
  const handleClickOverview = (event) => {
    setAnchorElOverview(event.currentTarget);
  };
  const handleCloseOverview = () => {
    setAnchorElOverview(null);
  };

  // Event handlers for the second dropdown
  const handleClickGetStarted = (event) => {
    setAnchorElGetStarted(event.currentTarget);
  };
  const handleCloseGetStarted = () => {
    setAnchorElGetStarted(null);
  };

  const location = useLocation();
  // const [isHovering, setIsHovering] = useState(false);
  return (
    <div className="root">
      <AppBar
        position="fixed"
        sx={{
          background: "linear-gradient(132deg, #22477E 30%, #3567B2 90%)",
          boxShadow: "0 3px 5px 2px rgb(90 78 80 / 42%)",
        }}
      >
        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            item
            xs={10}
            sm={10}
            md={6}
            lg={4}
            xl={4}
            style={{
              display: "flex",
              flexDirection: "column",
              //   justifyContent: "flex",
              //   alignItems: "center",
              //   backgroundColor: "red",
            }}
          >
            <Grid style={{ display: "flex", alignItems: "center", gap: "5px",padding:"0.2rem" }}>
              <a href="/" style={{}}>
                <img
                  src={Texas_Foster_Care256x256}
                  alt="fostering, Nurturing, adoption, Foster care, foster to adopt, nurturing"
                  height="60px"
                  width="60px"
                  style={{ paddingLeft: "1.2rem" }}
                />
              </a>
              <Link to="/" className="menuLinks">
                <Typography
                  variant="h1"
                  id="title"
                  style={{ fontSize:"1.1rem"}}
                >
                  TFCAS - Information about Texas Foster and Adoption Needs
                </Typography>
                <Typography id="title1" style={{ fontSize: "0.8rem" }}>
                  A Texas 501(c)(3) Non-Profit Agency
                </Typography>
              </Link>
            </Grid>
          </Grid>

          <Grid item xs={2} sm={2} md={6} lg={8} xl={8}>
            <Grid
              container
              style={{
                display: "flex",
                justifyContent: "flex-end",
                flexDirection: "column",
                flexWrap: "wrap",
              }}
            >
              <Grid item style={{ height: "50%" }}>
                <Grid
                  item
                  style={{
                    display: "flex",
                    justifyContent: "right",
                    alignItems: "center",
                    gap: "1px",
                  }}
                >
                  <Grid id="class">
                    <Grid
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        flexWrap: "wrap",
                        gap: "10px",
                      }}
                    >
                      <Grid item title="click to donate via PayPal">
                        <form
                          action="https://www.paypal.com/cgi-bin/webscr"
                          method="post"
                          target="_blank"
                        >
                          <input type="hidden" name="cmd" value="_s-xclick" />
                          <input
                            type="hidden"
                            name="hosted_button_id"
                            value="F83RSAG9W88KE"
                          />
                          <button
                            className="donate"
                            style={{ borderRadius: "20px", cursor: "pointer" }}
                          >
                            <MonetizationOnIcon size="2x" />
                          </button>
                        </form>
                      </Grid>

                      <Grid item>
                        <Tooltip title="Home">
                          <Link
                            to="/"
                            className="menuLinks"
                            variant="contained"
                            starticon={<HomeIcon />}
                            color="inherit"
                            style={{}}
                          >
                            <p
                              id="button"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "80px",
                                gap: "3px",
                                fontSize: "0.8rem",
                              }}
                            >
                              {" "}
                              <HomeIcon /> HOME
                            </p>
                          </Link>
                        </Tooltip>
                      </Grid>
                      <Grid item>
                        <Tooltip title="About Us">
                          <Link
                            to="/about"
                            className="menuLinks"
                            id="button"
                            variant="contained"
                            starticon={<InfoIcon />}
                            color="inherit"
                            style={{ fontSize: "0.8rem" }}
                          >
                            <p
                              id="button"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "3px",
                              }}
                            >
                              {" "}
                              <InfoIcon /> ABOUT US
                            </p>
                          </Link>
                        </Tooltip>
                      </Grid>
                      {location.pathname === "/" ? (
                        ""
                      ) : (
                        <Grid>
                          <Tooltip title="Overview">
                            <Grid
                              id="button"
                              aria-controls={
                                openOverview ? "overview-menu" : undefined
                              }
                              aria-haspopup="true"
                              aria-expanded={openOverview ? "true" : undefined}
                              variant="contained"
                              onClick={handleClickOverview}
                              endicon={<KeyboardArrowDownIcon />}
                              style={{
                                color: "white",
                                backgroundColor: "inherit",
                                fontSize: "0.8rem",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                                width: "120px",
                                gap: "3px",
                              }}
                            >
                              <SettingsAccessibilityIcon /> OVERVIEW{" "}
                              <KeyboardArrowDownIcon />
                            </Grid>
                          </Tooltip>
                          <StyledMenu
                            id="overview-menu"
                            MenuListProps={{
                              "aria-labelledby": "overview-button",
                            }}
                            anchorEl={anchorElOverview}
                            open={openOverview}
                            onClose={handleCloseOverview}
                          >
                            <Grid style={{ display: "flex" }}>
                              <Grid style={{}}>
                              
                                <MenuItem
                                  id="menuItems"
                                  onClick={handleCloseOverview}
                                  disablerippleclear
                                >
                                  <Link
                                    to="/fostercareOverview"
                                    className="menuLinks"
                                    style={{ color: "inherit" }}
                                  >
                                    <VolunteerActivismIcon id="menuItems_icon"></VolunteerActivismIcon>
                                    Foster Care Overview
                                  </Link>
                                </MenuItem>
                                <Divider sx={{ my: 0.5 }} />
                                <MenuItem
                                  id="menuItems"
                                  onClick={handleCloseOverview}
                                  disableRipple
                                >
                                  <Link
                                    to="/AdoptionOverview"
                                    className="menuLinks"
                                    style={{
                                      color: "inherit",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <FamilyRestroomIcon id="menuItems_icon" />
                                    Adoption Overview
                                  </Link>
                                </MenuItem>
                                <Divider sx={{ my: 0.5 }} />

                                <MenuItem
                                  id="menuItems"
                                  onClick={handleCloseOverview}
                                  disableRipple
                                >
                                  <Link
                                    to="/adoptionIncentivesview"
                                    className="menuLinks"
                                    style={{
                                      color: "inherit",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <PriceCheckIcon id="menuItems_icon" />
                                    Adoption Incentives
                                  </Link>
                                </MenuItem>

                                <Divider sx={{ my: 0.5 }} />

                                <MenuItem
                                  id="menuItems"
                                  onClick={handleCloseOverview}
                                  disableRipple
                                >
                                  <Link
                                    to="/agencylist"
                                    className="menuLinks"
                                    style={{
                                      color: "inherit",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <ManageSearchIcon id="menuItems_icon" />
                                    Agency Directory
                                  </Link>
                                </MenuItem>
                              </Grid>
                              {/* <Grid style={{ width: "50%", margin: "10px" }}>
                              <Typography
                                // variant="h6"
                                fontWeight="bold"
                                style={{
                                  fontSize: "1rem",
                                  textDecoration: "underline",
                                  textAlign: "center",
                                }}
                              >
                                CONTACT US
                              </Typography>
                              <br />
                              <MenuItem
                                id="menuItems"
                                onClick={handleCloseOverview}
                                disableRipple
                              >
                                <Link
                                  to="/fostercareOverview"
                                  className="menuLinks"
                                  style={{ color: "inherit" }}
                                >
                                  <VolunteerActivismIcon id="menuItems_icon"></VolunteerActivismIcon>
                                  Foster Care Overview
                                </Link>
                              </MenuItem>
                              <Divider />
                              <MenuItem
                                id="menuItems"
                                onClick={handleCloseOverview}
                                disableRipple
                              >
                                <Link
                                  to="/fostercareOverview"
                                  className="menuLinks"
                                  style={{ color: "inherit" }}
                                >
                                  <VolunteerActivismIcon id="menuItems_icon"></VolunteerActivismIcon>
                                  Foster Care Overview
                                </Link>
                              </MenuItem>
                              <Divider />
                              <MenuItem
                                id="menuItems"
                                onClick={handleCloseOverview}
                                disableRipple
                              >
                                <Link
                                  to="/fostercareOverview"
                                  className="menuLinks"
                                  style={{ color: "inherit" }}
                                >
                                  <VolunteerActivismIcon id="menuItems_icon"></VolunteerActivismIcon>
                                  Foster Care Overview
                                </Link>
                              </MenuItem>
                              <Divider />
                            </Grid> */}
                            </Grid>
                          </StyledMenu>
                        </Grid>
                      )}

                      <Grid>
                        <Tooltip title="Get Started">
                          <Grid
                            id="button"
                            aria-controls={
                              openGetStarted ? "getstarted-menu" : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={openGetStarted ? "true" : undefined}
                            variant="contained"
                            // disableElevation
                            onClick={handleClickGetStarted}
                            endicon={<KeyboardArrowDownIcon />}
                            style={{
                              color: "white",
                              backgroundColor: "inherit",
                              fontSize: "0.8rem",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              cursor: "pointer",
                              width: "144px",
                              gap: "3px",
                            }}
                          >
                            <FlagCircleIcon /> GET STARTED{" "}
                            <KeyboardArrowDownIcon />
                          </Grid>
                        </Tooltip>
                        <StyledMenu
                          id="getstarted-menu"
                          MenuListProps={{
                            "aria-labelledby": "getstarted-button",
                          }}
                          anchorEl={anchorElGetStarted}
                          open={openGetStarted}
                          onClose={handleCloseGetStarted}
                        >
                          <MenuItem
                            id="menuItems"
                            onClick={handleCloseGetStarted}
                            disableRipple
                          >
                            <Link
                              to="/generalInformation"
                              className="menuLinks"
                              style={{
                                color: "inherit",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <FlagIcon id="menuItems_icon" />
                              General Information
                            </Link>
                          </MenuItem>
                          <Divider sx={{ my: 0.5 }} />
                          {location.pathname === "/" ? (
                            ""
                          ) : (
                            <Grid>
                              <MenuItem
                                id="menuItems"
                                onClick={handleCloseGetStarted}
                                disableRipple
                              >
                                <Link
                                  to="/helpfulresources"
                                  className="menuLinks"
                                  style={{
                                    color: "inherit",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Diversity3Icon id="menuItems_icon" />
                                  Helpful Resources
                                </Link>
                              </MenuItem>
                              <Divider sx={{ my: 0.5 }} />
                            </Grid>
                          )}
                          <MenuItem
                            id="menuItems"
                            onClick={handleCloseGetStarted}
                            disableRipple
                          >
                            <Link
                              to="/requirements"
                              className="menuLinks"
                              style={{ color: "inherit" }}
                            >
                              <ChecklistRtlIcon id="menuItems_icon" />
                              Requirements
                            </Link>
                          </MenuItem>

                          <Divider sx={{ my: 0.5 }} />
                          <MenuItem
                            id="menuItems"
                            onClick={handleCloseGetStarted}
                            disableRipple
                          >
                            <Link
                              to="/stepsview"
                              className="menuLinks"
                              style={{
                                color: "inherit",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <AccountTreeIcon id="menuItems_icon" />
                              Steps
                            </Link>
                          </MenuItem>
                        </StyledMenu>
                      </Grid>

                      <Grid item>
                        <Tooltip title="Contact">
                          <Link
                            to="https://www.dfps.texas.gov/Child_Protection/Foster_Care/Litigation.asp"
                            target="_blank"
                            className="menuLinks"
                          >
                            <Grid
                              id="button"
                              className="menuBtn"
                              variant="contained"
                              starticon={<ContactPhoneIcon />}
                              // {...btnProp}
                              color="inherit"
                              style={{
                                backgroundColor: "unset",
                                fontSize: "0.8rem",
                                // boxShadow: "none",
                                padding: "6px",
                                // paddingTop: "15px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                                width: "80px",
                                gap: "5px",
                              }}
                            >
                              <ContactPhoneIcon /> NEWS
                            </Grid>
                          </Link>
                        </Tooltip>
                      </Grid>

                      <Grid item style={{}}>
                        <Tooltip title="Contact">
                          <Link to="/contact" className="menuLinks">
                            <Grid
                              id="button"
                              className="menuBtn"
                              variant="contained"
                              starticon={<ContactPhoneIcon />}
                              // {...btnProp}
                              color="inherit"
                              style={{
                                backgroundColor: "unset",
                                fontSize: "0.8rem",
                                // boxShadow: "none",
                                padding: "6px",
                                // paddingTop: "15px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                                width: "125px",
                                gap: "5px",
                              }}
                            >
                              <ContactPhoneIcon /> CONTACT US
                            </Grid>
                          </Link>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    id="toggelebtn"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Grid
                      id="paypalbutton"
                      item
                      title="click to donate via PayPal"
                      style={{ marginRight: "5px" }}
                    >
                      <form
                        action="https://www.paypal.com/cgi-bin/webscr"
                        method="post"
                        target="_blank"
                      >
                        <input type="hidden" name="cmd" value="_s-xclick" />
                        <input
                          type="hidden"
                          name="hosted_button_id"
                          value="F83RSAG9W88KE"
                        />
                        <button
                          className="donate"
                          style={{ borderRadius: "20px", marginTop: "0.2rem" }}
                        >
                          <MonetizationOnIcon size="2x" />
                        </button>
                      </form>
                    </Grid>

                    <Grid id="toggelebtn" style={{ marginRight: "5px" }}>
                      <Togglemenu />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AppBar>
    </div>
  );
};

export default Headermenu0;
