import * as React from "react";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
// import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import ImageSlider from "../imageslider";
import { Typography } from "@mui/material";
import { useState } from "react";
import axios from "axios";
import Autocomplete from "@mui/material/Autocomplete";
// import Papa from "papaparse";
import { useLocation } from "react-router-dom";
import "./contact.css";
// import Countylist from "../../fosteragencies_directory/countylists";
// import Autocomplete from "@mui/material/Autocomplete";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

function Contact() {
  //all the assigned names of the input textfields

  const InitialFormData = {
    emailType: "CONTACT_US",
    name: "",
    address: "",
    email: "",
    phoneNum: "",
    interest: "",
    sourceInfo: "",
    message: "",
    zipCode: "",
    city: "",
    state: "",
    counties: "",
    regions: "",
  };

  // defining the useState for the dataform
  const [formData, setFormData] = useState(InitialFormData);
  const [data, setData] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [countySelected, setCounty] = React.useState(null);
  const [texascounties, setCounties] = React.useState(null);

  const handleInputChange = async (event) => {
    let value = event.target.value;
    let name = event.target.name;
    let payload = {
      ...formData,
      [name]: value,
    };

    if (name === "phoneNum") {
      let phoneNumber = value.replace(/\D/g, ""); // Remove all non-digit characters

      // Limit the phone number to 10 digits
      if (phoneNumber.length > 10) {
        phoneNumber = phoneNumber.slice(0, 10);
      }

      if (phoneNumber.length === 10) {
        phoneNumber = phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
      }
      payload[name] = phoneNumber;
    }

    if (name === "zipCode") {
      let zipCode = value.replace(/\D/g, ""); // Remove all non-digit characters

      // Limit the zip code to 6 digits
      if (zipCode.length > 5) {
        zipCode = zipCode.slice(0, 5);
      }

      payload[name] = zipCode;
    }

    if (countyData && countyData["id"]) {
      payload.agencyId = countyData["id"];
    }
    if (countyData && countyData["id"]) {
      payload.county = countyData["county"];
    }
    if (countySelected) {
      payload.county = countySelected;
    }
    setFormData(payload);
  };


  //reset the form data;
  const resetForm = () => {
    setFormData(InitialFormData);
  };

  const [fetchedData, setFetchedData] = useState([]);
  // console.log(fetchedData);
  const handleBlur = async (zipCode) => {
    const zip = formData.zipCode;
    try {
      const response = await axios.post(

        "https://stage.texasfostercare.org:6001/getAddress",
        // "http://192.168.1.111:5000/getAddress",
        // "http://stage.texasfostercare.org:4202/getAddress",
        { zip },
        {
          headers: {
            "Content-Type": "text/json",
          },
        }
      );
      setFetchedData(response.data.result);
      setCounties(response.data.result.counties.split(','));
      console.log(formData.zipCode);
    } catch (error) {
      console.error("An error occurred during :", error);
      NotificationManager.error("Please Enter a Valid Zip Code or Zip code Within Texas state")
    }
  };

  const location = useLocation();
  const countyData =
    location.state && location.state.data ? location.state.data : null;
  // console.log(countyData);
  //on submit function;

  const handleOnSubmit = async (event) => {
    event.preventDefault();

    // Add the submitted formData to the data array

    if (countyData && countyData._id) {
      setData((prevData) => [...prevData, formData, countyData.id, countyData.county]);
      console.log(countyData.county)
    } else {
      setData((prevData) => [...prevData, formData]);
    }

    if (formData && formData.name === "") {
      NotificationManager.error("Please Fill Your Name");
      setIsSubmitting(false);
    } else {
      setData((prevData) => [...prevData, formData]);
    }

    if (formData && formData.email === "") {
      NotificationManager.error("Please Fill Your EmailId");
      setIsSubmitting(false);
    } else {
      setData((prevData) => [...prevData, formData]);
    }
    if (formData && formData.phoneNum === "") {
      NotificationManager.error("Please Fill Your PhoneNumber");
      setIsSubmitting(false);
    } else {
      setData((prevData) => [...prevData, formData]);
      setIsSubmitting(true);
    }
    if (formData && formData.interest === "") {
      NotificationManager.error("Please Select Any One Interest");
      setIsSubmitting(false);
    } else {
      setData((prevData) => [...prevData, formData]);
      setIsSubmitting(true);
    }
    if (formData && formData.zipCode === "") {
      NotificationManager.error("Please Fill Your Zip");
      setIsSubmitting(false);
    } else {
      setData((prevData) => [...prevData, formData]);
      setIsSubmitting(true);
    }
    if (formData && formData.counties === "") {
      NotificationManager.error("Please Select County");
      setIsSubmitting(false);
    } else {
      setData((prevData) => [...prevData, formData]);
      setIsSubmitting(true);
    }

    if (formData && formData.address === "") {
      NotificationManager.error("Please Fill Address");
      setIsSubmitting(false);
    } else {
      setData((prevData) => [...prevData, formData]);
      setIsSubmitting(true);
    }
    // if (formData && formData.sourceInfo === "") {
    //   NotificationManager.error("Please Enter How Did You Hear About Us");
    //   setIsSubmitting(false);
    // } else {
    //   setData((prevData) => [...prevData, formData]);
    //   setIsSubmitting(true);
    // }
    // if (formData && formData.message === "") {
    //   NotificationManager.error("Please Enter Your Message");
    //   setIsSubmitting(false);
    // } else {
    //   setData((prevData) => [...prevData, formData]);
    //   setIsSubmitting(true);
    // }

    try {
      const response = await axios.post(
        "https://stage.texasfostercare.org:6001/email",
        // "http://192.168.1.111:5000/email",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // response.emailSent && alert("Mail Sent Successfully");
      if (response.data.emailSent) {
        NotificationManager.success("YOUR DATA HAS BEEN SUBMITTED");
        // console.log("FORM DATA", formData);
        console.log(formData);
        setIsSubmitting(false);
        resetForm();
        // setCounties(null);
      } else {
        NotificationManager.error(
          "INTERNAL ERROR OCCURED DURING FORM SUBMISSION"
        );
        console.log(response.data.emailSent);
        setIsSubmitting(true);
      }
    } catch (error) {
      console.error("An error occurred during form submission:", error);
      NotificationManager.error(
        "INTERNAL ERROR OCCURED DURING FORM SUBMISSION"
      );
      setIsSubmitting(true);
    }
  };

  // const handleChange = (event, newValue) => {
  //   setCounty(newValue);
  // };

  //fetching the address
  React.useEffect(() => {
    if (fetchedData) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        city: fetchedData.city,
        // county: fetchedData.counties.split(','),
        regions: fetchedData.regions,
        state: fetchedData.state,
      }));
    }

  }, [fetchedData]);


  const handleChange = (event, newValue) => {
    setFormData({
      ...formData,
      counties: newValue,
    });
  };


  return (
    <div align="center" style={{ marginBottom: "2rem", }}>
      {/* <ImageSlider /> */}

      <Grid style={{ border: "1px solid black", backgroundColor: "white", width: "80%", padding: "20px", borderRadius: "10px" }}>

        <Grid style={{ paddingTop: "10px", marginBottom: "1rem" }}>
          <Typography
            variant="h5"
            style={{
              fontWeight: "bold",
              color: "rgb(53, 103, 178)",
              textDecoration: "underline",
            }}
          >
            CONTACT US
          </Typography>
          {/* <Button onClick={createNotification("error")}>open</Button> */}
        </Grid>

        {countyData && (
          <Grid item className="agencydetails" xs={12}>
            <Typography
              variant="h6"
              fontWeight="bold"
              style={{ color: "rgb(53, 103, 178)" }}
            >
              YOU ARE CONTACTING :
            </Typography>

            <Typography
              style={{
                // border: "2px solid black",
                // height: "40px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "5px",
                paddingRight: "10px",
                paddingLeft: "10px",
                borderRadius: "30px",
                backgroundColor: "#3876BF",
                fontWeight: "bold",
                color: "white",
              }}
            >
              {countyData.agencyName}
              {" | "}
              {countyData.agencyAddress}
              {", "}
              {countyData.state}
              {" - "}
              {countyData.zipCode}
            </Typography>
          </Grid>
        )}
        <form onSubmit={handleOnSubmit} style={{ marginTop: "1rem" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                //
                id="name"
                label="Name"
                type="text"
                name="name"
                fullWidth
                required
                variant="outlined"
                value={formData.name}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                id="email"
                label="Email"
                type="email"
                name="email"
                fullWidth
                required
                variant="outlined"
                value={formData.email}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="phoneNum"
                label="Phone"
                type="tel"
                name="phoneNum"
                required
                value={formData.phoneNum}
                onChange={handleInputChange}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="interest-label">Interest</InputLabel>
                <Select
                  labelId="interest-label"
                  id="native-select-interest"
                  label="Interest"
                  name="interest"
                  required
                  value={formData.interest}
                  onChange={handleInputChange}
                  style={{ textAlign: "left" }}
                >
                  <MenuItem value="Traditional Foster Care">
                    Traditional Foster Care
                  </MenuItem>
                  <MenuItem value="Foster to Adopt">Foster to Adopt</MenuItem>
                  <MenuItem value="Volunteer">Volunteer</MenuItem>
                  <MenuItem value="Respite Provider">Respite Provider</MenuItem>
                  <MenuItem value="Therapeutic Foster Care">
                    Therapeutic Foster Care
                  </MenuItem>
                  <MenuItem value="Charitable Contribution">
                    Charitable Contribution
                  </MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                type="number"
                fullWidth
                id="zipCode"
                required
                name="zipCode"
                label="ZipCode"
                value={formData.zipCode}
                onChange={handleInputChange}
                onBlur={handleBlur}
              // placeholder="Enter Zip Code"
              />
            </Grid>

            <Grid item xs={12}>
              <Grid container style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "1rem" }}>
                <Grid item lg={5.8} md={5.8} sm={12} xs={12}>
                  {fetchedData && (
                    <TextField
                      type="text"
                      fullWidth
                      id="city"
                      name="city"
                      label="City"
                      value={formData.city}
                      onChange={handleInputChange}
                      disabled
                    // onBlur={handleBlur}
                    // placeholder="Enter Zip Code"
                    />
                  )}
                </Grid>
                <Grid item lg={5.8} md={5.8} sm={12} xs={12}>
                  {/* {fetchedData && (
                    <TextField
                      type="text"
                      fullWidth
                      //
                      id="county"
                      name="county"
                      label="County"
                      value={formData.county}
                      onChange={handleInputChange}
                      disabled
                    />
                  )} */}
                  {fetchedData && texascounties ? (
                    <Autocomplete
                      onChange={handleChange}
                      disabled={!fetchedData}
                      options={fetchedData ? texascounties : ""}
                      style={{ width: "100%" }}
                      getOptionLabel={(option) => (option ? option : "")}
                      value={formData.counties}
                      required
                      isOptionEqualToValue={(option, value) => option === value}
                      renderInput={(params) => (
                        <TextField required {...params} label="Select County" />
                      )}
                    />
                  ) : (
                    <Autocomplete
                      // onChange={(event, newValue) => handleChange(event, newValue)}
                      disabled
                      // options={fetchedData ? texascounties : ""}
                      style={{ width: "100%" }}
                      getOptionLabel={(option) => (option ? option : "")}
                      // value={formData.county}
                      isOptionEqualToValue={(option, value) => option === value}
                      renderInput={(params) => (
                        <TextField required {...params} label="Select County" />
                      )}
                    />
                  )}
                </Grid>

              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "1rem" }}>
                <Grid item lg={5.8} md={5.8} sm={12} xs={12}>
                  {fetchedData && (
                    <TextField
                      type="text"
                      fullWidth
                      //
                      id="region"
                      name="region"
                      label="Region"
                      value={formData.regions}
                      onChange={handleInputChange}
                      disabled
                    // onBlur={handleBlur}
                    // placeholder="Enter Zip Code"
                    />
                  )}
                </Grid>
                <Grid item lg={5.8} md={5.8} sm={12} xs={12}>
                  {fetchedData && (
                    <TextField
                      type="text"
                      fullWidth
                      //
                      id="state"
                      name="state"
                      label="State"
                      value={formData.state}
                      onChange={handleInputChange}
                      disabled
                    // onBlur={handleBlur}
                    // placeholder="Enter Zip Code"
                    />
                  )}
                </Grid>

              </Grid>
            </Grid>

            <Grid item xs={12}>
              <TextField
                id="address"
                label="Address"
                type="text"
                name="address"
                required
                fullWidth
                variant="outlined"
                value={formData.address}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="source-label">
                  How Did You Hear About Us
                </InputLabel>
                <Select
                  labelId="source-label"
                  id="native-select-source"
                  name="sourceInfo"
                  required
                  label="How Did You Hear About Us"
                  value={formData.sourceInfo}
                  onChange={handleInputChange}
                  style={{ textAlign: "left" }}
                >
                  <MenuItem value="Social Media">Social Media</MenuItem>
                  <MenuItem value="From a friend">From a friend</MenuItem>
                  <MenuItem value="News Paper">News Paper</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                id="message"
                label="Message"
                name="message"
                multiline
                value={formData.message}
                onChange={handleInputChange}
                required
                rows={5}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                // fullWidth
                type="submit"
                fontWeight="bold"
                disabled={isSubmitting}
                style={{ width: "150px" }}
              >
                <span style={{ fontWeight: "bold", fontSize: "1rem" }}>
                  SEND
                </span>
              </Button>
            </Grid>
          </Grid>
        </form>
        <br />
        {countyData ? ("") : (
          <Grid
            style={{
              width: "90%",
              // height: "100px",
              backgroundColor: "#DAEAF1",
              border: "1px solid black",
              borderRadius: "10px",
              padding: "10px",
              textAlign: "left",
            }}
          >
            <i>
              <Typography
                style={{
                  color: "black",
                  fontWeight: "bold",
                  textDecoration: "underline",
                  textAlign: "center",
                }}
              >
                Desclaimer:
              </Typography>

              <ul style={{ top: 0 }}>
                <li>
                  <Typography>
                    Your information will be shared with all relevant agencies within your region. It will be used solely for the purpose of fulfilling the services and requirements of these agencies. Please ensure that the information you provide is accurate and up-to-date.
                  </Typography>
                </li>
              </ul>
            </i>
          </Grid>)}
      </Grid>
      <NotificationContainer />
    </div>
  );
}

export default Contact;
